/* FONT */
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap');
@font-face {
    font-family: 'SF Pro Text';
    src: url('./Assets/Font/SF-Pro-Text-Regular.otf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('./Assets/Font/SF-Pro-Text-Bold.otf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('./Assets/Font/SF-Pro-Text-Black.otf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('./Assets/Font/SF-Pro-Text-Light.otf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('./Assets/Font/SF-Pro-Text-Thin.otf');
    font-weight: 200;
    font-style: normal;
}
/* FONT */

/* START LAYOUT */
body {
    color: #000000;
}
body,
a,
div,
span {
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-size: 21px;
}
body,
#header {
    background-color: #e6f9ed;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'SF Pro Text';
    font-weight: 500;
}
h1 {
    font-size: calc(3.5rem + 0.9vw);
    line-height: 80px;
    font-style: italic;
    color: #00205c;
    font-weight: 600;
}
section.container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.img-fluid {
    width: 100%;
}
/* END LAYOUT */

/* START HEADER */
.navbar a {
    color: #001475;
    font-weight: 600;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #1155cc !important;
}

.navbar .language {
    cursor: pointer;
    margin-left: 1.5rem;
}
.navbar .language .icon img {
    width: 30px;
    height: 30px;
}
.navbar .language .icon .icon-selector {
    margin-right: 0.5rem;
}
.navbar .language ul {
    margin-top: 0.5rem;
    border: 2px solid #00205c;
    width: 43px;
    padding: 5px;
    background-color: transparent;
    border-radius: 22px;
}
.navbar .language ul li:not(:last-child) {
    margin-bottom: 1rem;
}
.navbar .language ul li img {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
}
.navbar .mobile-nav-toggle img {
    width: 30px;
}
/* END HEADER */

/* HERO */
#hero {
    background-image: url('./Assets/Image/hero-bg.png');
    height: 100vh;
    box-shadow: inset 0 0 0 1000px rgba(57, 103, 255, 0.2);
}
#hero h1 {
    max-width: 800px;
}
#hero .description {
    color: #ffffff;
    font-weight: 400 !important;
    margin-top: 1rem;
    max-width: 570px;
}
/* HERO */

/* BREADCRUMBS */
.breadcrumbs.bg-about-us {
    background-image: url('./Assets/Image/Header/about-us.png');
}
.breadcrumbs.bg-our-services {
    background-image: url('./Assets/Image/Header/our-services.png');
}
.breadcrumbs.bg-news {
    background-image: url('./Assets/Image/Header/news.png');
}
.breadcrumbs.bg-partners {
    background-image: url('./Assets/Image/Header/partner.png');
}
.breadcrumbs.bg-career {
    background-image: url('./Assets/Image/Header/career.png');
}
.breadcrumbs.bg-contact-us {
    background-image: url('./Assets/Image/Header/contact-us.png');
}
.breadcrumbs {
    height: 50vh;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    position: relative;
    box-shadow: inset 0 0 0 1000px rgba(22, 55, 163, 0.3);
}
.breadcrumbs .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.breadcrumbs .container h1 {
    margin-top: auto;
    margin-bottom: auto;
    color: #ffffff;
}
/* BREADCRUMBS */

/* FOOTER */
.footer-top {
    background-color: #00205c !important;
}
#footer {
    background-color: #ffffff;
}
footer .footer-contact img {
    width: 120px;
}
footer .footer-links .contact {
    font-family: 'Anonymous Pro', monospace !important;
    color: #ffffff;
    font-size: 26px;
}
footer .social-link,
.contact-us .social-link {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
}
footer .social-link img {
    width: 30px;
    height: 30px;
}
footer .footer-bottom div,
footer .footer-bottom div a {
    font-family: 'Anonymous Pro', monospace !important;
    color: #000000;
}
footer .footer-bottom a:hover {
    color: #00205c;
}
footer .footer-bottom div img {
    max-width: 60vw;
    margin-left: 2rem;
    margin-right: 2rem;
}
#footer .footer-top .footer-links {
    margin-bottom: 0;
}
footer .footer-top .social-link {
    margin-left: auto;
    margin-right: auto;
}

footer .footer-top .second-footer {
    margin-top: 0;
}

/* FOOTER */

/* HOME */
.card-service {
    padding-right: 10px;
}
.card-service .card-body {
    height: 330px;
    border-radius: 10px;
    border: 2px solid #00205c;
    padding: 10px;
}
.card-service img {
    max-height: 230px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
}
img.gallery {
    border-radius: 10px;
    padding: 5px;
    height: 326px;
    width: 100%;
    object-fit: cover;
}
.footer-line {
    background-color: #00205c;
    border-bottom: 2px solid #ffffff;
    opacity: 1;
    padding-bottom: 3rem;
}
/* HOME */

/* ABOUT US */
.about-us h2 {
    color: #00205c;
    font-weight: 600;
    font-style: italic;
}
.about-us .card-about-us {
    border-radius: 10px;
    position: relative;
}
.about-us .card-about-us img {
    width: 100%;
    height: 100%;
}
.about-us .card-about-us .body {
    position: absolute;
    bottom: 0;
    padding: 2rem;
}
.about-us .card-about-us .body h3 {
    color: #ffffff;
    font-weight: 600;
}
.about-us .card-about-us .body div {
    text-align: justify;
    color: #ffffff;
    font-size: 15px;
}
.about-us .commitment h5 span {
    background-color: rgba(0, 181, 88, 0.61);
}
.about-us .commitment img {
    height: 500px;
}
.about-us .team img {
    width: 100%;
    height: 250px;
    object-fit: contain;
}
.about-us .team h5.name {
    margin-top: 1rem;
    font-weight: 600;
    color: #00205c;
    font-style: italic;
}
.about-us .team h5.position {
    color: #000000;
    font-weight: 600;
    font-style: italic;
}
.about-us .value {
    text-align: justify !important;
}
.about-us .footer {
    margin-top: 1rem;
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
}
/* ABOUT US */

/* NEWS */
.news-card {
    background-color: #ffffff;
    height: 600px;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.56);
    position: relative;
    overflow-y: auto;
}
.news-card .news-img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-bottom: 1rem;
}
.news-card h4 {
    color: #00205c;
    font-weight: 600;
}
.news-card h5 {
    font-size: 16px;
    color: #000000;
    margin-bottom: 0;
}
.news-card .link {
    position: absolute;
    bottom: 0;
    padding-bottom: 2rem;
    margin-top: 0.5rem;
}
.news-card .link a {
    color: #00205c;
}
.news-card .link a span {
    font-weight: 600;
}
.news-card .link a img {
    height: 25px;
}
.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 2rem;
}
.pagination div {
    font-family: 'Anonymous Pro', monospace !important;
}
.pagination img {
    cursor: pointer;
}
.about-us-detail .img-news {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
}
.about-us-detail h3 {
    color: #00205c;
    font-weight: 600;
}
.about-us-detail .btn-all {
    background-color: #00205c;
    color: #ffffff;
    border-radius: 30px;
    border: none;
    padding: 10px 2rem;
}
/* NEWS */

/* CAREERS */
.careers .btn-all {
    background-color: transparent;
    font-weight: 600;
    color: #00205c;
    border-radius: 30px;
    border: 1px solid #00205c;
    width: 250px;
}
.careers .btn-all.true {
    background-color: #00205c;
    color: #ffffff;
}
.job div {
    color: #00205c;
}
.job .detail div {
    font-weight: 600;
}
.careers .btn-submit {
    background-color: #00205c;
    font-weight: 500;
    color: #ffffff;
    border-radius: 30px;
    padding: 0 2rem;
    border: none;
}
.careers .btn-submit.close {
    background-color: #ffffff;
    color: #00205c;
    border: 1px solid #00205c;
}
.careers h2 {
    color: #00205c;
    font-weight: 600;
    font-style: italic;
}
/* CAREERS */

/* CONTACT US */
.contact-us {
    background-color: #00205c;
    color: #ffffff;
}
.contact-us h1 {
    color: #ffffff;
}
.contact-us .maps {
    border-radius: 20px;
    margin-top: 3rem;
    height: 350px;
    width: 100%;
}
.map-container {
    border-radius: 10px;
    border: 2px solid #ffffff;
    height: 100%;
    width: 100%;
    margin: 0;
}
.contact-us .social-link {
    margin-bottom: 2rem;
}
.contact-us .info-maps {
    color: #000000;
    width: 300px;
}
.contact-us .info-maps div {
    font-size: 14px;
}
/* CONTACT US */

@media only screen and (min-width: 768px) {
    .about-us .card-about-us .body div {
        font-size: 21px;
    }

    footer .footer-top .social-link {
        margin-left: 0;
    }

    footer .footer-top .second-footer {
        margin-top: 45px;
    }
}

/** EXTENDED */
.ps-container {
    min-height: 100vh;
    position: relative;
    display: flex;
    gap: 4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 3rem;
    padding-top: 7rem;
}

.ps-logo {
    display: flex;
    justify-content: center;
}

.ps-logo img {
    width: 300px;
}

.ps-subcontainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
}

.ps-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.ps-item {
    border-radius: 10px;
    min-height: 600px;
    position: relative;
    transition: all;
}

.ps-read-more-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 32, 92, 0.4);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    opacity: 0;
    transition: all;
    transition-duration: 200ms;
}

.ps-item:hover .ps-read-more-container {
    opacity: 1;
    cursor: pointer;
}

.ps-item:hover {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.ps-item-bg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: -1;
}

.ps-item .item-container {
    height: 100%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    padding: 1rem;
}

.item-container .wrapper {
    min-height: 80px;
    margin-bottom: 0.5rem;
}

.ps-item .item-container .wrapper > h2 {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 24px;
}

.ps-item .item-container .wrapper > p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: lighter;
    line-height: 1.25rem;
}

@media only screen and (max-width: 1100px) {
    .ps-subcontainer {
        gap: 1rem;
    }

    .ps-item {
        min-height: 500px;
    }
}

@media only screen and (max-width: 768px) {
    .ps-subcontainer {
        grid-template-columns: auto;
    }
}
